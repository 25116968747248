<template>
  <div class="line-item p-2 mb-3">
    <div class="row g-0 align-items-center">
      <div class="col-2 line-item-price">
        <component :is="icon" />
        ${{ price / 100 }}
      </div>
      <div class="col px-2">
        <div class="line-item-title">{{ title }}</div>
        {{ description }}
      </div>
      <div class="col-2 px-1">
        <div class="row flex-column">
          <div class="col text-center">
            <Chevron
              @click="increaseAmount"
              style="width: 25px; height: 25px; transform: rotate(-90deg); cursor: pointer;"
            />
          </div>
          <div class="col text-center">
            <input class="form-control" type="number" v-model="amount" />
          </div>
          <div class="col text-center">
            <Chevron
              @click="decreaseAmount"
              style="width: 25px; height: 25px; transform: rotate(90deg); cursor: pointer;"
            />
          </div>
        </div>
      </div>
      <div class="col-1">
        <div class="line-item-total">${{ total / 100 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Chevron from "@/assets/chevron.svg?inline";

export default {
  name: "LineItem",

  props: ["title", "icon", "price", "description"],

  components: { Chevron },

  computed: {
    total: function() {
      return this.price * this.amount;
    }
  },

  methods: {
    increaseAmount: function() {
      this.amount++;
    },

    decreaseAmount: function() {
      if (this.amount > 0) {
        this.amount--;
      }
    }
  },

  data() {
    return {
      amount: 0
    };
  },

  watch: {
    amount: function(newAmount) {
      this.$emit("onChange", newAmount);
    }
  }
};
</script>

<style>
.line-item {
  border-radius: 5px;
  border: 2px solid #b1b6bc;
}

.line-item-price,
.line-item-total {
  color: #b1b6bc;
  text-align: center;
  font-weight: 700;
}

.line-item-title {
  font-weight: 700;
}

.line-item:hover {
  border: 2px solid #000;
  background-color: #eef2f4;
}

.line-item:hover .line-item-total {
  color: #000;
}

.line-item:hover input {
  color: #000;
  font-weight: 700;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
}
</style>

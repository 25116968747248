<template>
  <div class="container">
    <Divider title="Delivery details" />
    <div class="row">
      <div class="col">
        <div class="mb-2">
          <label for="firstName" class="form-label">First name</label>
          <ValidationProvider v-slot="v" rules="required">
            <input
              type="text"
              class="form-control"
              id="firstName"
              :value="firstName"
              @input="onChange('firstName', $event.target.value)"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="col">
        <div class="mb-2">
          <label for="lastName" class="form-label">Last name</label>
          <ValidationProvider v-slot="v" rules="required">
            <input
              type="text"
              class="form-control"
              id="lastName"
              :value="lastName"
              @input="onChange('lastName', $event.target.value)"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-2">
          <label for="Mobile" class="form-label">Mobile</label>
          <ValidationProvider v-slot="v" rules="required">
            <input
              type="text"
              class="form-control"
              id="mobile"
              :value="mobile"
              @input="onChange('mobile', $event.target.value)"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-2">
          <label for="email" class="form-label">Email</label>
          <ValidationProvider mode="lazy" v-slot="v" rules="required|email">
            <input
              type="email"
              class="form-control"
              id="email"
              :value="email"
              @input="onChange('email', $event.target.value)"
            />
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-2">
          <label for="deliveryAddress" class="form-label"
            >Delivery address</label
          >
          <ValidationProvider v-slot="v" rules="required|distance">
            <places-autocomplete
              class="form-control"
              :value="deliveryAddress"
              @place-changed="data => onChange('deliveryAddress', data)"
            >
            </places-autocomplete>
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-2">
          <label for="collectionAddress" class="form-label"
            >Collection address</label
          >
          <ValidationProvider v-slot="v" rules="required|distance">
            <places-autocomplete
              class="form-control"
              :value="collectionAddress"
              @place-changed="data => onChange('collectionAddress', data)"
            >
            </places-autocomplete>
            <span class="text-danger">{{ v.errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <Divider title="Any delivery instructions?" />
    <div class="row">
      <div class="col">
        <div class="mb-2">
          <textarea
            class="form-control"
            id="deliveryInstructions"
            rows="3"
            @input="onChange('comments', $event.target.value)"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

import Divider from "@/components/Divider";
import PlacesAutocomplete from "@/components/PlacesAutocomplete";

/**
 * Calculates the haversine distance between point A, and B in kilometers.
 * @param {number[]} latlngA [lat, lng] point A
 * @param {number[]} latlngB [lat, lng] point B
 */
const haversineDistance = ([lat1, lon1], [lat2, lon2]) => {
  const toRadian = angle => (Math.PI / 180) * angle;
  const distance = (a, b) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_KM = 6371;

  const dLat = distance(lat2, lat1);
  const dLon = distance(lon2, lon1);

  lat1 = toRadian(lat1);
  lat2 = toRadian(lat2);

  // Haversine Formula
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  return RADIUS_OF_EARTH_IN_KM * c;
};

extend("email", { ...email, message: "Email is not valid" });

extend("required", {
  ...required,
  message: "This field is required"
});

extend("distance", {
  validate: value => {
    const melbourneCenter = ["-37.8102", "144.9628"];
    const distanceFromCBD = haversineDistance(
      [value.latitude, value.longitude],
      melbourneCenter
    );
    return isNaN(distanceFromCBD) || distanceFromCBD < 25;
  },
  message: "Oops! This is beyond our current service area."
});

export default {
  name: "DeliveryDetails",

  props: [
    "firstName",
    "lastName",
    "mobile",
    "email",
    "deliveryAddress",
    "collectionAddress",
    "onChange"
  ],

  components: {
    Divider,
    ValidationProvider,
    PlacesAutocomplete
  },

  methods: {}
};
</script>

<style>
label {
  font-weight: bold;
  margin-bottom: 0.2rem !important;
}
</style>

<template>
  <div class="flex-container">
    <template class="$route.query.faq !== undefined ? 'd-none' : ''">
      <Hero />
      <div class="container-slim container-slim-pull mx-auto mb-5">
        <ValidationObserver v-slot="{ invalid, handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <TubAmountSelector
              :amount="amount"
              :onAmountSelect="amount => handleFormUpdate('amount', amount)"
              :estimator="estimator"
              :onEstimatorSelect="value => (estimator = value)"
            />
            <Calendar
              :deliveryDate="deliveryDate"
              :collectionDate="collectionDate"
              :onDeliveryDateSelect="
                date => handleFormUpdate('deliveryDate', date)
              "
              :onCollectionDateSelect="
                date => handleFormUpdate('collectionDate', date)
              "
              v-if="wasFilled.amount"
            />
            <div class="container">
              <span
                class="text-danger"
                v-if="deliveryDate && collectionDate && !isSevenDays"
              >
                You can book for less than 7 days, but the minimum charge is for
                7 days.
              </span>
            </div>
            <div class="container" v-if="wasFilled.amount">
              <div class="row" v-if="estimator">
                <span class="text-danger"
                  >Please finish the estimation step to get a price.</span
                >
              </div>
            </div>
            <div
              class="container my-2"
              v-if="wasFilled.amount && !estimator && availableLineItems"
              style="border-bottom: 1px solid #000"
            >
              <div
                class="row align-items-center pb-3"
                @click="availableLineItemsVisible = !availableLineItemsVisible"
                style="cursor: pointer"
              >
                <div class="col" style="font-weight: 700;">
                  Handy Extras
                </div>
                <div class="col-2 text-right">
                  <Chevron
                    :class="
                      availableLineItemsVisible ? 'down-chevron' : 'up-chevron'
                    "
                    style="width: 25px; height: 25px;"
                  />
                </div>
              </div>
              <div
                :class="[
                  availableLineItemsVisible
                    ? 'height-transition'
                    : 'height-transition height-transition-hidden'
                ]"
              >
                <LineItem
                  v-for="item in availableLineItems"
                  :icon="iconForItemCode(item.code)"
                  :price="item.price"
                  :title="item.title"
                  :description="item.description"
                  :key="item.code"
                  @onChange="amount => updateLineItems(item.code, amount)"
                />
              </div>
            </div>
            <div class="container" v-if="wasFilled.amount && total">
              <div class="row price mt-4 mb-5">
                <div class="col">
                  Total:
                </div>
                <div class="col text-end fw-bold">
                  ${{ (total / 100).toFixed(1) }}0
                </div>
              </div>
            </div>
            <DeliveryTime
              :deliveryDate="deliveryDate"
              :collectionDate="collectionDate"
              :deliveryTime="deliveryTime"
              :collectionTime="collectionTime"
              :onDeliveryTimeSelect="
                time => handleFormUpdate('deliveryTime', time)
              "
              :onCollectionTimeSelect="
                time => handleFormUpdate('collectionTime', time)
              "
              v-if="wasFilled.deliveryDate && wasFilled.collectionDate"
            />
            <span v-if="wasFilled.deliveryTime && wasFilled.collectionTime">
              <DeliveryDetails
                :firstName="firstName"
                :lastName="lastName"
                :mobile="mobile"
                :email="email"
                :deliveryAddress="deliveryAddress"
                :collectionAddress="collectionAddress"
                :onChange="handleFormUpdate"
              />
              <div class="container my-2">
                <input
                  ref="terms"
                  type="checkbox"
                  class="form-check-input me-2"
                  id="terms"
                  :value="termsAgreed"
                  @input="
                    event =>
                      handleFormUpdate('termsAgreed', event.target.checked)
                  "
                />
                <label for="terms" class="form-check-label"
                  >I agree to the
                  <router-link to="terms" target="_blank">
                    Terms and Conditions
                  </router-link>
                </label>
              </div>
              <div class="container my-3">
                <button
                  type="submit"
                  class="btn btn-primary btn-lg w-100"
                  :disabled="!isComplete || invalid"
                >
                  Book now
                </button>
              </div>
            </span>
          </form>
        </ValidationObserver>
      </div>
      <div id="bottom-scroll-anchor"></div>
      <div class="footer text-center p-4">
        <p>
          <a href="tel:93806444">9380 6444</a> |
          <a href="mailto:tubs@littleredtrucks.com.au"
            >tubs@littleredtrucks.com.au</a
          >
        </p>
        <p>© Little Red Tubs {{ currentYear }}</p>
      </div>
    </template>
    <FAQ v-if="$route.query.faq !== undefined" />
  </div>
</template>

<script>
import axios from "axios";
import { ValidationObserver } from "vee-validate";
import VueScrollTo from "vue-scrollto";
import Vue from "vue";

import Hero from "@/components/Hero";
// Tubs hibernation
// import TubAmountSelector from "@/components/TubAmountSelector.vue";
import Calendar from "@/components/Calendar.vue";
import DeliveryTime from "@/components/DeliveryTime.vue";
import DeliveryDetails from "@/components/DeliveryDetails.vue";
import FAQ from "@/components/FAQ.vue";
import LineItem from "@/components/LineItem.vue";

import Chevron from "@/assets/chevron.svg?inline";
import EcoBubbleWrap from "@/assets/eco_bubble_wrap.svg?inline";
import TVBox from "@/assets/tv_box.svg?inline";
import Tape from "@/assets/tape.svg?inline";
import RedBag from "@/assets/tote_bag.svg?inline";

export default {
  name: "LittleRedTubs",

  components: {
    Hero,
    // Tubs hibernation
    // TubAmountSelector,
    Calendar,
    DeliveryTime,
    DeliveryDetails,
    FAQ,
    ValidationObserver,
    Chevron,
    LineItem
  },

  mounted() {
    axios.get("/api/tubs/line_items").then(response => {
      this.availableLineItems = response.data;
    });
  },

  data: () => ({
    amount: null,
    deliveryDate: null,
    collectionDate: null,
    deliveryTime: null,
    collectionTime: null,
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    deliveryAddress: {},
    collectionAddress: {},
    comments: "",
    lineItems: {},
    wasFilled: {},
    estimator: true,
    availableLineItemsVisible: true,
    availableLineItems: null,
    termsAgreed: false
  }),

  computed: {
    formMissingFields: function() {
      const required = [
        "firstName",
        "lastName",
        "mobile",
        "email",
        "deliveryAddress",
        "collectionAddress",
        "termsAgreed"
      ];
      const missingFields = [];
      for (const field of required) {
        if (
          !this[field] ||
          (typeof this[field] === "object" &&
            Object.keys(this[field]).length === 0)
        ) {
          missingFields.push(field);
        }
      }
      return missingFields;
    },

    total: function() {
      if (this.amount && this.collectionDate && this.deliveryDate) {
        const pricePerDay = 46; // In cents
        return (
          pricePerDay *
            this.amount *
            Math.max(
              7,
              this.collectionDate.diff(this.deliveryDate, "days") + 1
            ) +
          this.lineItemTotal
        );
      }
      return null;
    },

    lineItemTotal: function() {
      const totals = Object.entries(this.lineItems).map(([code, amount]) => {
        const price = this.availableLineItems.find(f => f.code === code).price;
        return amount * price;
      });
      return totals.reduce((x, acc) => x + acc, 0);
    },

    isSevenDays: function() {
      return (
        this.deliveryDate &&
        this.collectionDate &&
        this.collectionDate.diff(this.deliveryDate, "days") >= 6
      );
    },

    isComplete: function() {
      return this.formMissingFields.length === 0 && this.termsAgreed;
    },

    postPayload: function() {
      return {
        amount: this.amount,
        delivery_date: this.deliveryDate.format("YYYY-MM-DD"),
        collection_date: this.collectionDate.format("YYYY-MM-DD"),
        delivery_time: this.deliveryTime,
        collection_time: this.collectionTime,
        first_name: this.firstName,
        last_name: this.lastName,
        mobile: this.mobile,
        email: this.email,
        delivery_address: this.deliveryAddress,
        collection_address: this.collectionAddress,
        delivery_comments: this.comments,
        line_items: this.lineItems
      };
    },

    currentYear() {
      return new Date().getFullYear()
    },
  },

  methods: {
    iconForItemCode: function(code) {
      return (
        {
          eco_wrap: EcoBubbleWrap,
          red_bag: RedBag,
          tape: Tape
        }[code] || TVBox
      );
    },

    updateLineItems: function(code, amount) {
      if (amount === 0) {
        Vue.delete(this.lineItems, code);
      } else {
        Vue.set(this.lineItems, code, amount);
      }
      this.$forceUpdate();
    },

    onSubmit: function() {
      axios
        .post("/api/tubs/booking", this.postPayload, {
          headers: { "Content-Type": "application/json" }
        })
        .then(response => {
          const stripe = window.Stripe(
            "pk_live_51J7CC4I3esmkXyL4Z1sofsSrMJPZopnnz0fZeeF9Uo0ioBlIC1bBA0K4lG2OAM20s8QnuSCicmsWKtAGqMbOpIpt00PG21z5Ng"
          );
          stripe.redirectToCheckout({
            sessionId: response.data.stripe_checkout_id
          });
        });
    },

    handleFormUpdate: function(field, value) {
      let shouldScrollDown = false;
      // Only scroll down on the first time something is completed
      if (!this.wasFilled[field]) {
        if (field === "amount") {
          // Amount was previously null and is now not null
          shouldScrollDown = this.amount === null && value > 0;
        } else if (field === "deliveryDate" || field === "collectionDate") {
          // Scroll down if both dates are complete
          shouldScrollDown =
            this.deliveryDate !== null &&
            field === "collectionDate" &&
            value !== null;
        } else if (field === "deliveryTime" || field === "collectionTime") {
          // Scroll down if either time is filled out because the time component
          // reveals in two steps
          shouldScrollDown = this[field] === null;
        } else {
          // Scroll time if any form field was previously not set and is now
          // set and it was the last missing form field
          shouldScrollDown =
            !this[field] && value && this.formMissingFields === [field];
        }
      }

      Vue.set(this, field, value);

      // Mark field as previously filled
      this.wasFilled[field] = true;

      if (shouldScrollDown) {
        // Scroll to bottom of shouldScrollDown bool is true
        VueScrollTo.scrollTo("#bottom-scroll-anchor");
      }
    }
  }
};
</script>

<style>
.down-chevron {
  transform: rotate(90deg);
}

.up-chevron {
  transform: rotate(-90deg);
}

.height-transition {
  -moz-transition: -moz-transform 1s;
  -o-transition: -o-transform 1s;
  -webkit-transition: -webkit-transform 1s;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  -webkit-transform-origin: top;
  transform-origin: top;
  transition: height 1s;
  height: 280px;
}

.height-transition-hidden {
  height: 0;
  overflow-y: hidden;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.footer {
  margin-top: auto;
  z-index: 3;
}

</style>

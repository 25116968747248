<template>
  <div class="container">
    <Divider title="And for how long?" />
    <div class="row calendar mb-3">
      <div class="col text-center">
        <div class="row pb-3 month-select">
          <div class="col-3 text-start prev-month">
            <a
              href
              @click.prevent="selectedMonth = previousMonth"
              v-if="!previousMonthHasPassed"
            >
              &lt; {{ previousMonth | moment("MMM") }}
            </a>
          </div>
          <div class="col current-month">
            {{ selectedMonth | moment("MMMM YYYY") }}
          </div>
          <div class="col-3 text-end next-month">
            <a href @click.prevent="selectedMonth = nextMonth">
              {{ nextMonth | moment("MMM") }} &gt;
            </a>
          </div>
        </div>

        <div class="row gx-0">
          <div class="col p-2 header">M</div>
          <div class="col p-2 header">T</div>
          <div class="col p-2 header">W</div>
          <div class="col p-2 header">Th</div>
          <div class="col p-2 header">F</div>
          <div class="col p-2 header">S</div>
          <div class="col p-2 header">S</div>
        </div>

        <div
          class="row gx-0 mb-1"
          v-for="(days, week, index) in datesByWeek"
          :key="index"
        >
          <template v-if="index === 0">
            <div
              class="col"
              :class="classForDate(day)"
              v-for="day in lastDaysFromPreviousMonth(7 - days.length)"
              :key="day.format()"
            >
              <div
                class="day p-2 opaque"
                @click="
                  selectDay(day);
                  selectMonth(previousMonth);
                "
                :class="{ disabled: dayCompare(day) === -1 }"
              >
                <span :class="{ today: dayCompare(day) === 0 }">
                  {{ day | moment("D") }}
                </span>
              </div>
            </div>
          </template>

          <div
            class="col text-center"
            v-for="(day, index) in days"
            :class="classForDate(day)"
            :key="index"
          >
            <div
              @click="selectDay(day)"
              :class="{ disabled: dayCompare(day) === -1 }"
              class="day p-2"
            >
              <span :class="{ today: dayCompare(day) === 0 }">
                {{ day | moment("D") }}
              </span>
            </div>
          </div>

          <template v-if="index !== 0">
            <div
              class="col opaque"
              :class="classForDate(day)"
              v-for="day in firstDaysFromNextMonth(7 - days.length)"
              :key="day.format()"
            >
              <div
                @click="
                  selectDay(day);
                  selectMonth(nextMonth);
                "
                class="day p-2"
              >
                {{ day | moment("D") }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import Divider from "@/components/Divider";

export default {
  name: "Calendar",

  props: [
    "deliveryDate",
    "collectionDate",
    "onDeliveryDateSelect",
    "onCollectionDateSelect"
  ],

  components: {
    Divider
  },

  computed: {
    datesByWeek() {
      const daysInMonth = moment(this.selectedMonth).daysInMonth();
      const datesByWeek = {};
      for (let i = 1; i <= daysInMonth; i++) {
        const current = moment(this.selectedMonth).date(i);
        // Necessary to include the year in the key because sometimes week 1
        // starts in December of the previous year
        const key = current.year() + "-" + current.isoWeek();
        if (datesByWeek[key] === undefined) {
          datesByWeek[key] = [];
        }
        datesByWeek[key].push(current);
      }
      return datesByWeek;
    },

    previousMonth() {
      return moment(this.selectedMonth)
        .subtract(1, "months")
        .startOf("month");
    },

    previousMonthHasPassed() {
      return this.previousMonth.isBefore(moment().startOf("month"));
    },

    nextMonth() {
      return moment(this.selectedMonth)
        .add(1, "months")
        .startOf("month");
    }
  },

  data() {
    return {
      today: moment(),
      selectedMonth: moment().startOf("month")
    };
  },

  methods: {
    dayCompare(day) {
      if (day.isSame(moment(), "day")) {
        return 0;
      } else if (day.isBefore(moment())) {
        return -1;
      } else {
        return 1;
      }
    },

    lastDaysFromPreviousMonth(count) {
      const numberOfDays = this.previousMonth.daysInMonth();
      const days = [];
      for (let i = 0; i < count; i++) {
        const current = moment(this.previousMonth).date(numberOfDays - i);
        days.push(current);
      }
      return days.reverse();
    },

    firstDaysFromNextMonth(count) {
      const days = [];
      for (let i = 1; i <= count; i++) {
        const current = moment(this.nextMonth).date(i);
        days.push(current);
      }
      return days;
    },

    selectDay(date) {
      // Only allow day selection if day has not passed
      if (this.dayCompare(date) !== -1) {
        if (this.deliveryDate && this.collectionDate) {
          // Clear previous dates
          this.onDeliveryDateSelect(date);
          this.onCollectionDateSelect(null);
        } else if (this.deliveryDate) {
          if (date.isAfter(this.deliveryDate)) {
            this.onCollectionDateSelect(date);
          } else {
            // Attempting to select collection date that is before the currently
            // selected delivery date. Assume attempt to change delivery date.
            this.onDeliveryDateSelect(date);
          }
        } else {
          this.onDeliveryDateSelect(date);
        }
      }
    },

    classForDate(date) {
      if (date.isSame(this.deliveryDate, "day")) {
        return "start-highlight";
      } else if (date.isSame(this.collectionDate, "day")) {
        return "end-highlight";
      } else if (
        date.isAfter(this.deliveryDate) &&
        date.isBefore(this.collectionDate)
      ) {
        return "highlight";
      }
    },

    selectedDayIn(days) {
      return (
        days.find(day => day.isSame(this.selectedDay, "day")) !== undefined
      );
    },

    selectMonth(month) {
      if (!month.isBefore(moment().startOf("month"))) {
        this.selectedMonth = month;
      }
    }
  }
};
</script>

<style scoped>
.calendar {
  border-bottom: 1px solid #000000;
}

a {
  color: #b1b6bc;
  text-decoration: underline;
}

.month-select {
  font-size: 1.3rem;
  border-bottom: 1px solid #000000;
}

.prev-month,
.next-month {
  padding-top: 0.5rem;
  font-weight: bold;
  font-size: 0.875rem;
  color: #e6e6e6;
}

.prev-month a,
.next-month a {
  text-decoration: none;
}

.current-month {
  font-weight: bold;
  font-size: 1.25rem;
}

.header {
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 0;
  margin-bottom: 10px;
  font-size: 0.875rem;
}

.day {
  cursor: pointer;
  font-weight: bold;
  color: #b1b6bc;
  font-size: 0.875rem;
}

.disabled {
  opacity: 0.25 !important;
  cursor: inherit !important;
}

.start-highlight {
  background: #f03d53;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.highlight {
  background: #f03d53;
}

.end-highlight {
  background: #f03d53;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.start-highlight > .day,
.highlight > .day,
.end-highlight > .day {
  color: white;
}

.opaque {
  color: #e5e5e5;
}

.today {
  margin: 0 auto;
  border-radius: 25px;
  background: #b1b6bc;
  color: white;
  padding: 0.2rem 0.5rem;
}

.price {
  padding-top: 10px;
  margin: 0 auto;
  font-size: 0.6rem;
}
</style>

<template>
  <div class="container">
    <Divider
      title="What's a good delivery time"
      :subtitle="'on ' + deliveryDate.format('LL') + '?'"
    />

    <div class="row gx-3">
      <div class="col" v-for="time in times" :key="time.description">
        <div
          class="time-box py-3"
          :class="deliveryTime === time.timeRange ? 'active' : ''"
          @click="() => onDeliveryTimeSelect(time.timeRange)"
        >
          <span class="title">{{ time.description }}</span>
          {{ time.timeRange }}
        </div>
      </div>
    </div>

    <span v-if="deliveryTime">
      <Divider
        title="And a collection time"
        :subtitle="'on ' + collectionDate.format('LL') + '?'"
        :no-border="true"
      />

      <div class="row gx-3">
        <div class="col" v-for="time in times" :key="time.description">
          <div
            class="time-box py-3"
            :class="collectionTime === time.timeRange ? 'active' : ''"
            @click="() => onCollectionTimeSelect(time.timeRange)"
          >
            <span class="title">{{ time.description }}</span>
            {{ time.timeRange }}
          </div>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import Divider from "@/components/Divider";

export default {
  name: "DeliveryTime",

  props: [
    "deliveryDate",
    "deliveryTime",
    "onDeliveryTimeSelect",
    "collectionDate",
    "collectionTime",
    "onCollectionTimeSelect"
  ],

  components: {
    Divider
  },

  data: () => ({
    times: [
      { description: "Morning", timeRange: "7am - 10am" },
      { description: "Lunch", timeRange: "11am - 1pm" },
      { description: "Arvo", timeRange: "2pm - 5pm" }
    ]
  })
};
</script>

<style>
.time-box {
  border: 4px solid #b1b6bc;
  border-radius: 10px;
  text-align: center;
  font-size: 0.8rem;
  color: #b1b6bc;
}

.time-box .title {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
}

.time-box:hover,
.time-box.active {
  cursor: pointer;
  color: #f03d53;
  border-color: #f03d53;
}
</style>

<template>
  <div class="faq z-index-6" id="faq">
    <div class="close-icon top-right-icon">
      <Close @click="close" />
    </div>

    <div class="container-slim mx-auto p-4" id="top">
      <div class="row">
        <div class="col text-center">
          <h2>FAQs</h2>
        </div>
      </div>
      <div class="divider mt-4 mb-3 pb-1">
        <div class="title">The Tubs</div>
      </div>

      <div class="title">
        How do they work?
      </div>
      <p>
        Pack whatever will fit in them, close the lid and stack them ready to
        move. No tape, no mess, no clutter.
      </p>

      <div class="title">
        How big are they?
      </div>
      <p>
        Tubs are:
      </p>

      <TubWidth />
      <p class="text-center">60cm long</p>

      <TubDepth />
      <p class="text-center">40cm wide</p>

      <TubHeight />
      <p class="text-center">31.5cm tall</p>

      <div class="title">
        How much can they hold?
      </div>
      <p>
        Tubs have a capacity of 55 litres. That's generally enough to fit....
      </p>

      <TubClothes />
      <p class="text-center">Two drawers of clothes</p>

      <TubPotsPans />
      <p class="text-center">A medium set of pots and pans</p>

      <TubPaperbacks />
      <p class="text-center">Around 55 paperback books</p>

      <div class="title">How much weight can they take?</div>
      <p>
        Tubs are designed to carry up to 50 kilograms without much fuss. Mind
        your back lifting a heavily loaded tub though.
      </p>

      <div class="title">How do I label on them?</div>
      <p>
        Just use the masking tape and marker pen we throw in with every order.
      </p>

      <div class="title">Can I buy your tubs?</div>
      <p>Sorry, our tubs are not for sale.</p>

      <div class="title">Do I need to use a Little Red Truck?</div>
      <p>
        Not at all. While we highly recommend you do move with LRT, we're happy
        to provide tubs for any Movee in Melbourne.
      </p>

      <div class="title">Can I store tubs with you?</div>
      <p>Not just yet. But watch this space :)</p>

      <div class="divider mt-4 mb-3 pb-1">
        <div class="title">The Delivery</div>
      </div>

      <div class="title">Where do you deliver?</div>
      <p>
        We deliver anywhere within a 25 kilometre radius of the Melbourne CBD.
      </p>

      <div class="title">What if I'm outside the delivery zone?</div>
      <p>
        At the moment, we can only deliver and collect from within our delivery
        zone.
      </p>

      <div class="title">How much is delivery?</div>
      <p>Delivery is free!</p>

      <div class="title">What if I'm not home?</div>
      <p>
        We can always leave your tubs in a safe place. Keep in mind you are
        responsible for all tubs once delivery has been made.
      </p>

      <div class="title">Do you bring them upstairs?</div>
      <p>Yes, of course. We'll place the tubs anywhere you require them.</p>

      <div class="title">How do I extend my booking?</div>
      <p>Give us a bell on 9380 6444 and we'll sort it out.</p>

      <div class="divider mt-4 mb-3 pb-1">
        <div class="title">The Collection</div>
      </div>

      <div class="title">How do I ready my tubs for collection?</div>
      <p>
        Peel off any labels, give them a wipe out with a damp cloth and stack in
        managable stacks near your front door.
      </p>

      <p class="my-5 text-center links">
        <a href="tel:93806444">9380 6444</a> |
        <a href="mailto:tubs@littleredtrucks.com.au"
          >tubs@littleredtrucks.com.au</a
        >
      </p>

      <button
        class="btn btn-link btn-sm red text-center w-100"
        v-scroll-to="{
          el: '#top',
          container: '#faq',
          duration: 500,
          lazy: false,
          easing: 'linear',
          offset: -200,
          force: true,
          cancelable: true,
          onStart: onStart,
          onDone: onDone,
          onCancel: onCancel,
          x: false,
          y: true
        }"
      >
        Back to top
      </button>
      <button class="mt-3 btn btn-primary w-100" @click="close">
        Order Tubs
      </button>
    </div>
  </div>
</template>

<script lang="js">
import VueScrollTo from "vue-scrollto";

import TubClothes from "@/assets/tub_clothes.svg?inline";
import TubPaperbacks from "@/assets/tub_paperbacks.svg?inline";
import TubPotsPans from "@/assets/tub_pots_pans.svg?inline";
import TubWidth from "@/assets/tub_width.svg?inline"
import TubHeight from "@/assets/tub_height.svg?inline"
import TubDepth from "@/assets/tub_depth.svg?inline"
import Close from "@/assets/close.svg?inline";

import router from '@/router'

export default {
  name: 'FAQ',
  components: {
    TubClothes,
    TubPaperbacks,
    TubPotsPans,
    TubWidth,
    TubHeight,
    TubDepth,
    Close
  },
  methods: {
    close: function() {
      router.push({ path: '/' })
    },
    scrollToTop: function() {
      VueScrollTo.scrollTo({
        el: '#top',
        container: '#faq'
      })
    }
  }
}
</script>

<style>
.faq {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  height: 100%;
  width: 100%;
  background: white;
  z-index: 1050;
  overflow-y: scroll;
}

.title {
  font-weight: bold;
}

.links a {
  font-weight: 700;
  color: #000;
}

.close-icon > svg > path {
  fill: #f03d53;
}
</style>

<template>
  <div>
    <div class="top-right-icon">
      <QuestionMark @click="openFaq" />
    </div>
    <div
      class="nav-header row g-0 align-items-center fixed-top"
      style="min-height: 90px;"
    >
      <div class="col-3 col-sm-2 col-lg-1 p-3 text-lg-start">
        <a href="/">
          <logo></logo>
        </a>
      </div>
      <div class="col-6 p-3">
        Moving boxes delivered free by Little Red Trucks
      </div>
    </div>
  </div>
</template>

<script lang="js">
import Logo from '@/components/Logo';
import QuestionMark from "@/assets/question_mark.svg?inline";
import router from '@/router'

export default {
  name: 'Header',

  components: {
    Logo,
    QuestionMark
  },

  methods: {
    openFaq: function() {
      router.push({ path: '/', query: { faq: null }})
    }
  }
}
</script>

import axios from "axios";
import moment from "moment";

import Vue from "vue";
import App from "./App.vue";
import VueMoment from "vue-moment";
import VueScrollTo from "vue-scrollto";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from "./router";

if (process.env.NODE_ENV && process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = `http://littleredtrucks.localdomain:9001/`;
  Vue.config.devtools = true;
} else {
  axios.defaults.baseURL = `https://littleredtrucks.com.au/`;
}

Vue.config.productionTip = false;
Vue.use(VueMoment, { moment });
Vue.use(VueScrollTo);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");

<template>
  <LogoIcon />
</template>

<script>
import LogoIcon from "@/assets/logo.svg?inline";

export default {
  name: "Logo",

  components: {
    LogoIcon
  }
};
</script>

<style scoped>
svg {
  width: 100%;
  max-width: 80px;
}
</style>

<template>
  <div class="divider mt-4 mb-3 pb-1" :class="{ 'no-border': noBorder }">
    <div class="title">{{ title }}</div>
    <div class="subtitle" v-if="subtitle">{{ subtitle }}</div>
  </div>
</template>

<script>
export default {
  name: "Divider",
  props: ["title", "subtitle", "noBorder"]
};
</script>

<style>
.divider {
  margin: 1rem auto;
  border-bottom: 4px solid #000;
}

.divider.no-border {
  border-bottom: none;
}

.title {
  font-weight: bold;
  line-height: 1rem;
}
</style>

<template>
  <input
    ref="autoComplete"
    type="text"
    :id="id"
    :placeholder="placeholder"
    :value="value.formatted_address"
    @focus="onFocus()"
    @blur="onBlur()"
    @input="onInput"
    @keypress="onKeyPress"
  />
</template>

<script>
export default {
  name: "PlacesAutocomplete",

  props: {
    placeholder: {
      type: String,
      default: "Enter an address"
    },
    options: {
      type: Object,
      default: () => {
        return {
          // Latitude and longitude bounds should encompass all of Victoria
          bounds: new window.google.maps.LatLngBounds(
            new window.google.maps.LatLng("-39.264537", "140.988653"),
            new window.google.maps.LatLng("-33.959714", "150.480840")
          ),
          strictBounds: true,
          fields: [
            "place_id",
            "formatted_address",
            "address_components",
            "geometry"
          ],
          types: ["address"]
        };
      }
    },
    value: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    id() {
      return this._uid;
    }
  },

  data() {
    return {
      autoComplete: null,
      geocoder: null
    };
  },

  methods: {
    initAutocomplete() {
      this.autoComplete = new window.google.maps.places.Autocomplete(
        document.getElementById(this.id),
        this.options
      );

      this.geocoder = new window.google.maps.Geocoder();

      this.autoComplete.addListener("place_changed", () => {
        const place = this.autoComplete.getPlace();

        const addressComponents = {
          subpremise: "long_name",
          street_number: "long_name",
          route: "long_name",
          locality: "long_name",
          administrative_area_level_1: "short_name",
          administrative_area_level_2: "long_name",
          administrative_area_level_3: "long_name",
          country: "long_name",
          postal_code: "short_name"
        };

        let returnData = {};
        if (place.address_components !== undefined) {
          // Get each component of the address from the place details
          for (let i = 0; i < place.address_components.length; i++) {
            let addressType = place.address_components[i].types[0];

            if (addressComponents[addressType]) {
              let val =
                place.address_components[i][addressComponents[addressType]];
              returnData[addressType] = val;
            }
          }

          let subpremiseFromInput = null;
          let streetNumberFromInput = null;
          const regex = RegExp("(\\d+)/(\\d+).*Australia");
          const match = regex.exec(this.$refs.autoComplete.value);
          if (Array.isArray(match)) {
            subpremiseFromInput = match[1];
            streetNumberFromInput = match[2];
          }

          // Always override the subpremise, if we didn't match the regex we don't
          // want one even if the address has one
          returnData["subpremise"] = subpremiseFromInput;
          if (match) {
            // Override the street number with ours if regex matched because the
            // address returned by geocoder might be wrong
            returnData["street_number"] = streetNumberFromInput;
          }
          returnData["place_id"] = place.place_id;
          returnData["latitude"] = place.geometry.location.lat();
          returnData["longitude"] = place.geometry.location.lng();
          returnData["formatted_address"] = place.formatted_address;

          // return returnData object and PlaceResult object
          this.$emit("place-changed", returnData);
        }
      });
    },

    onFocus() {
      this.$emit("focus");
    },

    onBlur() {
      this.$emit("blur");
    },

    onInput(event) {
      this.$emit("input", event);
    },

    onKeyPress(event) {
      this.$emit("keypress", event);
    },

    clear() {
      this.autoCompletetext = "";
    }
  },

  mounted() {
    this.initAutocomplete();
  },

  watch: {
    options: function() {
      this.initAutocomplete();
    }
  }
};
</script>

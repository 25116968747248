<template>
  <div class="hero">
    <Header />
    <div class="row g-0" style="padding-top: 90px;">
      <div class="col-12 col-lg-6 slope-left green-bg">
        <div
          class="d-flex flex-column justify-content-end h-100 position-relative pt-5"
        >
          <div class="illustration" :class="backgroundClass"></div>
          <div class="blurb px-5 z-index-2">
            <!-- <div class="d-block mb-4"> -->
            <div class="row p-4">
              <h1>We're hibernating for the winter</h1>
              <!-- <h1>Very handy tubs for hire</h1>
              <hr /> -->
            </div>
            <!-- <p>
              Little Red Tubs are purpose-built, heavy-duty plastic moving boxes
              from your friends at Little Red Trucks. These stackable, 55-litre
              beauties make moving cleaner, quicker and more sustainable than
              ever before. Little Red Tubs can be booked by the day and are
              delivered and collected FREE anywhere in our Metropolitan
              Melbourne delivery zone.
            </p>
            <p>
              Ready to save time, money AND the planet? Order your Little Red
              Tubs today!
            </p> -->
          </div>
          <div class="d-flex d-lg-none triangle-cutout"></div>
          <div class="d-none d-lg-flex slope-up z-index-3"></div>
        </div>
      </div>
      <div class="col-12 col-lg-6 slope-right grey-bg">
        <div class="d-flex flex-column justify-content-end h-100">
          <div class="row p-4">
            <h1>We'll be back this spring!</h1>
            <!-- <div class="col-12 text-center text-md-left margin-pull">
              <div class="large-title mb-2">
                Tubs are
              </div>
              <DownArrow class="dark-arrow mb-3" />
            </div>
            <div class="hand-icon col-4 text-end position-relative">
              <HandEconomical />
              <div class="dashed-line"></div>
            </div>
            <div class="col-6">
              <div class="d-inline-block">
                <h2>Economical</h2>
                <hr />
              </div>
              <p>
                Yes! Little Red Tubs are cheaper than most of those expensive
                single-use cardboard boxes others peddle. AND we bring them to
                you!
              </p>
            </div>
            <div class="hand-icon col-4 text-end position-relative">
              <HandEcological />
              <div class="dashed-line"></div>
            </div>
            <div class="col-6">
              <div class="d-inline-block">
                <h2>Eco-friendly</h2>
                <hr />
              </div>
              <p>
                Hooray! Our tubs are made from recyclable plastic and designed
                to be used for 25 years. That’s all creating less impact on our
                precious environment than a myriad of discarded boxes, tapes and
                packing materials.
              </p>
            </div>
            <div class="hand-icon col-4 text-end">
              <HandEasy />
            </div>
            <div class="col-6">
              <div class="d-inline-block">
                <h2>Easy as</h2>
                <hr />
              </div>
              <p>
                Ordered in seconds, delivered and collected for free AND they
                help speed up your move? It couldn’t be any easier!
              </p>
            </div> -->
          </div>
          <div class="d-lg-none triangle-cutout"></div>
          <div class="d-none d-lg-block slope-down z-index-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import Header from '@/components/Header'
// Tubs hibernation
// import DownArrow from "@/assets/icon_down_arrow.svg?inline";
// import HandEasy from "@/assets/icon_hand_easy.svg?inline";
// import HandEconomical from "@/assets/icon_hand_economical.svg?inline";
// import HandEcological from "@/assets/icon_hand_ecological.svg?inline";

export default {
  name: 'Hero',

  props: ['title'],

  computed: {
    backgroundClass: function() {
      return this.backgrounds[this.backgroundIndex]
    }
  },

  data: function () {
    return {
      backgroundIndex: 0,
      backgrounds: [
        'illustration-one',
        'illustration-two',
        'illustration-three',
        'illustration-four'
      ]
    }

  },

  created () {
    setInterval(() => {
      if (this.backgrounds.length > this.backgroundIndex + 1) {
        this.backgroundIndex++;
      } else {
        this.backgroundIndex = 0;
      }
    }, 4000)
  },

  destroyed () {
    clearInterval()
  },

  components: {
    Header,
    // Tubs hibernation
    // DownArrow,
    // HandEasy,
    // HandEconomical,
    // HandEcological,
  }
}
</script>

<style>
.nav-header {
  background: #f03d53;
  color: white;
  font-size: 12px;
  font-weight: 700;
}

.hero p {
  font-size: 1rem;
  line-height: 1.219rem;
}

.hero {
  /* Tubs hibernation */
  /* margin-bottom: 5rem; */
  margin-bottom: 8rem;
  /* height: 100vh; */
  /* TODO The height was causing issues with the footer on small screens, check if this remains once tubs comes out of hibernation */
}

.hand-icon svg > path {
  fill: #000;
}

.illustration {
  background-color: #7ee6c7;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.illustration-one {
  background-image: url("../assets/illustration_hero_01.svg");
}

.illustration-two {
  background-image: url("../assets/illustration_hero_02.svg");
}

.illustration-three {
  background-image: url("../assets/illustration_hero_03.svg");
}

.illustration-four {
  background-image: url("../assets/illustration_hero_04.svg");
}

.grey-bg {
  background-color: #eef2f4;
  margin-left: -1px;
}

.green-bg {
  background-color: #7ee6c7;
}

.triangle-cutout {
  height: 150px;
  clip-path: polygon(0 100%, 50% 0, 100% 101%);
}

.grey-bg .triangle-cutout {
  background-color: #fff;
}

.green-bg .triangle-cutout {
  background-color: #eef2f4;
}

.slope-up,
.slope-down {
  height: 200px;
  background-color: #fff;
  position: relative;
}

.slope-up {
  clip-path: polygon(0% 100%, 100% 0, 100% 101%, 0% 100%);
}

.slope-down {
  clip-path: polygon(0 0, 100% 100%, 0% 100%, 0 0%);
}

.blurb {
  padding-top: 22rem;
}

@media (max-width: 575px) {
  .illustration {
    background-position-x: 40%;
    background-size: 130%;
  }

  .blurb {
    padding-top: 14rem;
  }
}

@media (max-width: 991px) {
  .margin-pull {
    margin-top: -5rem !important;
    z-index: 4;
  }
}

.dashed-line {
  position: absolute;
  top: 90px;
  bottom: 0;
  right: 60px;
  border-right: 2px dotted #2a2d42;
}

.dark-arrow > path {
  fill: #2a2d42;
}
</style>
